* {
  margin: 0;
}

body {
  background-color: #222;
}

canvas {
  padding: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: -150px;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid black;
}

#control {
  position: absolute;
  top: 300px;
  bottom: 0;
  left: 0;
  right: 250px;
  margin: auto;
  width: 106px;
  height: 100px;
  text-align: center;
}

#chat {
  position: absolute;
  top: 300px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 350px;
  height: 100px;
}

#messages {
  height: 90px;
  border: 1px solid black;
  margin-top: 5px;
  overflow-y: scroll;
  border-radius: 8px;
  background-color: white;
  padding: 12px;
}

input[type=text] {
  margin-top: 5px;
  max-width: 330px;
  border: none;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  outline: none;
}

#control button {
  margin: 5px;
  padding: 5px;
}

.code {
  color: white;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
}

.code:hover,
.code:active {
  color: white;
}